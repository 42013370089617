body {
  font-family: 'Noto Sans', 'Arial', 'Helvetica', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo{
  width: 8rem;
  height: 8rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiTableSortLabel-root{
font-weight: bold;
}


.icon{
  color: #2196f3;
}

h6#react-admin-title{
  font-weight: bold;
  font-size: 1.5rem;
}

header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionFixed.MuiAppBar-colorSecondary.mui-fixed.MuiPaper-elevation1{
  height: 70px;
  justify-content: center;
  color: #2196f3;
}

.forid{
  margin-left: 17rem;
  margin-top: -4.7rem;
}



@media (max-width: 600px) {

h6#react-admin-title{
  font-size: 1rem;
}

.logo{
margin-right: -5rem;
}

.nombreee{
  margin-left: 5rem;
}

}



